import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FeatureContent from '../components/feature-content'
import Banner from '../components/block-content/banner'
import Break from '../components/block-content/break'
import { colors } from '../styles/variables'

export default function Brand(props) {
  const { brands, name, subHeading, description, images } = props?.pageContext

  function getButtons(links) {
    let buttons = []
    if (links?.facebook) {
      buttons.push({ text: 'Facebook', link: links?.facebook })
    }
    if (links?.twitter) {
      buttons.push({ text: 'Twitter', link: links?.twitter })
    }
    if (links?.instagram) {
      buttons.push({ text: 'Instagram', link: links?.instagram })
    }
    if (links?.youtube) {
      buttons.push({ text: 'Youtube', link: links?.youtube })
    }
    if (links?.pinterest) {
      buttons.push({ text: 'Pinterest', link: links?.pinterest })
    }
    if (links?.linkedin) {
      buttons.push({ text: 'Linkedin', link: links?.linkedin })
    }
    // if (links?.tiktok) {
    //   buttons.push({ text: 'TikTok', link: links?.  })
    // }
    return buttons
  }

  return (
    <Layout>
      <SEO title={name} />
      <Banner
        node={{
          bold: true,
          italic: true,
          heading: name,
          headingColor: colors.white,
          subHeading: subHeading || ' ',
          subHeadingColor: colors.white,
          body: description,
          bodyColor: colors.white,
          image: images[0],
          overlay: false,
          opacity: 0.8,
          overlayColor: colors.white,
          wide: true,
          align: 'left'
        }}
      />
      <div style={{ height: '100px' }} />
      <FeatureContent
        heading={{
          text: 'OUR BRANDS',
          color: colors.primary,
          bold: true,
          italic: true,
          size: '2'
        }}
      />
      <div style={{ height: '30px' }} />
      {brands.map((brand, index) => {
        return (
          <>
            {index % 2 === 0 ? (
              <FeatureContent
                heading={{
                  text: brand?.name,
                  color: colors.secondary,
                  bold: true,
                  size: '0.85'
                }}
                brand={{
                  brand: brand?.name,
                  link: brand?.links?.site,
                  externalMessage: brand?.externalMessage,
                  buttons: getButtons(brand?.links)
                }}
                media={brand?.logo?.asset?.url}
                background={brand?.background?.asset?._id}
                bodyColor={colors.secondaryLightest}
                forcedHeight={300}
                height={300}
                width={675}
                content={[
                  {
                    _type: 'block',
                    _key: brand?.name.toLowerCase(),
                    style: 'normal',
                    children: [
                      {
                        _type: 'span',
                        _key: `${brand?.name.toLowerCase()}-description`,
                        text: brand?.description,
                        marks: []
                      }
                    ],
                    markDefs: []
                  }
                ]}
              />
            ) : (
              <FeatureContent
                heading={{
                  text: brand?.name,
                  color: colors.secondary,
                  bold: true,
                  size: '0.85'
                }}
                imageRight={true}
                brand={{
                  brand: brand?.name,
                  link: brand?.links?.site,
                  externalMessage: brand?.externalMessage,
                  buttons: getButtons(brand?.links)
                }}
                media={brand?.logo?.asset?.url}
                background={brand?.background?.asset?._id}
                bodyColor={colors.secondaryLightest}
                forcedHeight={300}
                height={300}
                width={675}
                content={[
                  {
                    _type: 'block',
                    _key: brand?.name.toLowerCase(),
                    style: 'normal',
                    children: [
                      {
                        _type: 'span',
                        _key: `${brand?.name.toLowerCase()}-description`,
                        text: brand?.description,
                        marks: []
                      }
                    ],
                    markDefs: []
                  }
                ]}
              />
            )}
            {index !== brands.length - 1 && <div style={{ height: '20px' }} />}
          </>
        )
      })}
      <Break node={{ height: '3' }} />
    </Layout>
  )
}
